import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'

export interface ActionButton {
    url: string;
    name: string;
    class: string;
    emits?: { actionName: string, actionValue?: any };
}

@Module
export default class ActionButtonModule extends VuexModule {
    actionButton = {} as ActionButton;

    /**
     * Get action button properties
     * @returns object
     */
    get getActionButton () {
      return this.actionButton
    }

    @Mutation
    [Mutations.SET_PAGE_TITLE_BUTTON_MUTATION] (payload) {
      this.actionButton = payload
    }

    @Action
    [Actions.SET_PAGE_TITLE_BUTTON_ACTION] (payload) {
      this.context.commit(Mutations.SET_PAGE_TITLE_BUTTON_MUTATION, payload)
    }
}
