import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'
import { UploadProgress, PayloadUploadProgress } from '@/store/modules/attachment/uploadProgress'

@Module
export default class AttachmentModule extends VuexModule {
    fileUploadProgress: UploadProgress = {}

    get getFileUploadProgressForEntity (): (entityUuid: any) => number {
      const _this = this

      return function (entityUuid: any): number {
        if (typeof entityUuid !== 'string') {
          return 100
        }
        return _this.fileUploadProgress[entityUuid] as number
      }
    }

    @Action({ rawError: true })
    async [Actions.API_UPLOAD_ATTACHMENT] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/attachment/upload', payload.data, payload.config)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Mutation
    [Mutations.SET_ATTACHMENT_UPLOAD_PROGRESS] (payload: PayloadUploadProgress) {
      this.fileUploadProgress[payload.entityUuid] = payload.progress
    }
}
