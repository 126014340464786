import { Action, Module, VuexModule } from 'vuex-module-decorators'
import { Actions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

@Module
export default class IconFinderModule extends VuexModule {
    photos = [] as any

    @Action({ rawError: true })
    async [Actions.API_ICONFINDER_SEARCH_ICONS] (params: { page: 0, query: 'interface' }) {
      return new Promise((resolve, reject) => {
        const payload = params as any
        console.log('params', params)
        ApiService.post('api/v1/iconfinder/search', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPLOAD_ICONFINDER_IMAGE] (params) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + params.templateUuid + '/iconfinder/upload', params)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
}
