import { Account } from '@/store/modules/AccountModule'

const ACTIVE_ACCOUNT = 'active_account' as string

/**
 * @description get the active account from localStorage
 */
export const getActiveAccount = (): Account | null => {
  const user = window.localStorage.getItem(ACTIVE_ACCOUNT)
  if (user !== null) {
    return JSON.parse(user)
  }
  return user
}

/**
 * @description save  account  into localStorage
 * @param activeAccount
 */
export const saveActiveAccount = (activeAccount: Account): void => {
  window.localStorage.setItem(ACTIVE_ACCOUNT, JSON.stringify(activeAccount))
}

/**
 * @description remove account form localStorage
 */
export const destroyActiveAccount = (): void => {
  window.localStorage.removeItem(ACTIVE_ACCOUNT)
}

export default { getActiveAccount, saveActiveAccount, destroyActiveAccount }
