// @ts-ignore
import io, { Socket } from 'socket.io-client'
import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { uuid } from 'vue-uuid'
import moment from 'moment'
import store from '@/store'
import { computed } from 'vue'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { MenuComponent } from '@/assets/ts/components'

const log = require('../../core/server/utils/log')
// @ts-ignore
window.io = require('socket.io-client')

const dotenv = require('dotenv')
dotenv.config()

interface SocketPingData {
    pingRequestId: string,
    emmitTime: number,
    emmitDateTime: string,
    maxPingResponseTime: number;
}

@Module
export default class WebsocketModule extends VuexModule {
    adminErrors = {} as any
    socket = {} as Socket
    pingTimer: number | undefined = 0;
    lastPingRequestId: string | null = null;
    lastPingFailed: boolean = false;
    maxPingResponseTime = 5;
    pingEnabled: boolean = true;
    showMultipleWindowsError = false;
    showWebsocketError = false;

    /**
     * Get socket
     * @returns Template
     */
    get getSocket () {
      return this.socket
    }

    get getLastPingRequestId () {
      return this.lastPingRequestId
    }

    get getPingTimer () {
      return this.pingTimer
    }

    get getAdminErrors () {
      return this.adminErrors
    }

    get showingMultipleWindowsError () {
      return this.showMultipleWindowsError
    }

    get showingWebsocketError () {
      return this.showWebsocketError
    }

    @Mutation
    [Mutations.SET_ADMIN_ERRORS] (error) {
      this.adminErrors = error
    }

    @Mutation
    [Mutations.SET_SHOW_MULTIPLE_WINDOWS_ERROR] (flag) {
      this.showMultipleWindowsError = flag
    }

    @Mutation
    [Mutations.SET_SHOW_WEBSOCKET_ERROR] (flag) {
      this.showWebsocketError = flag
    }

    @MutationAction
    async [MutationsActions.SET_MUTATE_ACTIVE_SOCKET] (activeSocket) {
      const socket = await activeSocket
      return { socket }
    }

    @Mutation
    [Mutations.SET_LAST_PING_REQUEST_ID] (lastPingRequestId: string) {
      this.lastPingRequestId = lastPingRequestId
    }

    @Mutation
    [Mutations.SET_PING_TIMER] (pingTimer) {
      this.pingTimer = pingTimer
    }

    @Mutation
    [Mutations.CLEAR_TIMEOUT_PING_TIMER] () {
      window.clearTimeout(this.pingTimer)
    }

    @Action
    [Actions.DISCONNECT_SOCKET] () {
      this.socket.disconnect()
    }

    @Action
    [Actions.CREATE_SOCKET_CONNECTION] (userSessionId) {
      const activityTemplate = computed(() => store.getters.getActivityTemplate)
      const url = process.env.VUE_APP_ADMIN_WSS_PROTOCOL + '://' + process.env.VUE_APP_ADMIN_WSS_HOSTNAME + ':' + process.env.VUE_APP_ADMIN_WSS_PORT
      const handleDraftUpdate = () => {
        if (activityTemplate.value.status === 2) {
          const newActivityTemplate = activityTemplate.value
          newActivityTemplate.status = 1
          store.dispatch(Actions.API_MAKE_DRAFT_TEMPLATE, activityTemplate.value.activityTemplateUuid).then((response) => {
            store.commit(Mutations.UPDATE_LOCAL_TEMPLATE, newActivityTemplate)
          }).catch((response) => {
            if (response.data.error.code !== 'activityTemplate.entity.ready.could.not.be.validated') {
              Swal.fire({
                width: 600,
                title: 'Could not change status',
                html: response.data.error.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Okay',
                customClass: {
                  container: 'ready-switch-html-container-error',
                  confirmButton: 'btn fw-bold btn-light-danger'
                }
              })
            }
          })
        }
      }

      const socket = io(url, {
        query: { userSessionId },
        // withCredentials: false,
        // autoConnect: true,
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000

      })

      socket.on('connect', () => { this.context.dispatch(Actions.ON_SOCKET_CONNECTED).then() })
      socket.on('connect_error', (eventData) => { this.context.dispatch(Actions.ON_SOCKET_CONNECTION_ERROR, eventData).then() })
      socket.on('reconnect', (eventData) => { this.context.dispatch(Actions.ON_SOCKET_RECONNECTED, eventData).then() })
      socket.on('disconnect', (eventData) => { this.context.dispatch(Actions.ON_SOCKET_DISCONNECTED, eventData).then() })
      socket.on('error', (eventData) => { this.context.dispatch(Actions.ON_SOCKET_ERROR, eventData).then() })
      socket.on('PING_RESPONSE', (eventData) => { this.context.dispatch(Actions.ON_PING_RESPONSE, eventData).then() })
      socket.on('SERVICE_MESSAGE', (eventData) => {
        console.log('service message received, ', eventData)
        if (eventData.type === 'MULTIPLE_WINDOWS') {
          this.context.commit(Mutations.SET_SHOW_MULTIPLE_WINDOWS_ERROR, true)
        }
      })
      socket.on('WIDGET_ADDED', (eventData) => {
        handleDraftUpdate()
        const payload = {
          widget: eventData.widget.content.widget,
          widgetPosition: eventData.widget.content.widgetPosition
        }
        this.context.commit(Mutations.SET_TEMPLATE_ADD_WIDGET, payload)
        setTimeout(() => {
          MenuComponent.reinitialization()
        }, 200)
      })

      socket.on('WIDGET_EDITED', (eventData) => {
        handleDraftUpdate()
        // const activityTemplate = computed(() => { return store.getters.getActivityTemplate })
        // const locatorInstance = buildWidgetLocator(activityTemplate, eventData.content.widget.widgetUuid)
        // const payload = {
        //   widget: eventData.content.widget,
        //   activeSectionIndex: locatorInstance.activeSectionIndex,
        //   activeWidgetIndex: locatorInstance.activeWidgetIndex
        // }
        this.context.commit(Mutations.UPDATE_WIDGET_EDIT_WIDGET, eventData.content)
      })

      socket.on('SECTION_ADDED', (eventData) => {
        handleDraftUpdate()

        this.context.commit(Mutations.SET_TEMPLATE_ADD_SECTION, eventData.content.section)
      })

      socket.on('SECTION_EDITED', (eventData) => {
        handleDraftUpdate()
        this.context.commit(Mutations.SET_TEMPLATE_EDIT_SECTION, { sectionIndex: eventData.content.locator.activeSectionIndex, section: eventData.content.section })

        console.log('Section edited successfully')
      })

      socket.on('IMAGE_360_HOTSPOT_EDITED', (eventData) => {
        this.context.commit(Mutations.UPDATE_WIDGET_EDIT_HOTSPOT, { widgetUuid: eventData.widgetUuid, hotspot: eventData.hotspot })
      })

      socket.on('SECTION_LAYOUT_EDITED', (eventData) => {
        handleDraftUpdate()

        console.log('Section layout edited successfully')
      })

      socket.on('SECTION_REMOVED', (eventData) => {
        handleDraftUpdate()

        this.context.commit(Mutations.SET_TEMPLATE_REMOVE_SECTION, eventData.widget.content.locator.activeSectionIndex)
        console.log(eventData)
      })

      socket.on('WIDGET_REMOVED', (eventData) => {
        handleDraftUpdate()

        this.context.commit(Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID, eventData.content.widgetUuid)
      })

      socket.on('ADMIN_ERROR', (eventData) => {
        this.context.commit(Mutations.SET_ADMIN_ERRORS, eventData.error)
      })

      socket.on('LEARNING_PATH_UPDATED', (eventData) => {
        this.context.commit(Mutations.SET_LEARNING_PATH, eventData.learningPath)
      })

      socket.on('OPEN_AI_MESSAGE_ATTACHMENT_PROCESSED', (eventData) => {
        this.context.commit(Mutations.UPDATE_OPEN_AI_MESSAGE, eventData.message)
      })

      // socket.on('IMAGE_360_HOTSPOT_ADDED', (eventData) => {
      //   const payload = {
      //     widgetUuid: eventData.widgetUuid,
      //     sceneUuid: eventData.scene.sceneUuid,
      //     hotspot: eventData.hotspot
      //   }
      //   this.context.commit(Mutations.UPDATE_WIDGET_ADD_HOTSPOT_TO_SCENE, payload)
      // })
      this.context.dispatch(MutationsActions.SET_MUTATE_ACTIVE_SOCKET, socket).then()
    }

    @Action
    [Actions.ON_SOCKET_CONNECTED] () {
      console.log('SOCKET MANAGER', 'Socket CONNECTED.')
      this.context.dispatch(Actions.PING_REQUEST).then()
      this.context.commit(Mutations.SET_SHOW_WEBSOCKET_ERROR, false)
    }

    @Action
    [Actions.ON_SOCKET_CONNECTION_ERROR] (error) {
      console.log('SOCKET MANAGER', 'Socket connection error.', error)
      this.context.dispatch(Actions.PING_REQUEST).then()
      const socketError = {
        code: 'socket.error',
        message: 'There was an error while connecting to the websocket.'
      }
      this.context.commit(Mutations.SET_ADMIN_ERRORS, socketError)
      this.context.commit(Mutations.SET_SHOW_WEBSOCKET_ERROR, true)
    }

    @Action
    [Actions.ON_SOCKET_RECONNECTED] () {
      console.log('SOCKET MANAGER', 'Socket RECONNECTED')
      // this.emmitConnected()
    }

    @Action
    [Actions.ON_SOCKET_DISCONNECTED] () {
      console.log('SOCKET MANAGER', 'Socket DISCONNECTED.')
      this.context.commit(Mutations.SET_SHOW_WEBSOCKET_ERROR, true)
    }

    @Action
    [Actions.ON_SOCKET_ERROR] (eventData) {
      console.log('SOCKET MANAGER', 'Socket ERROR. ' + JSON.stringify(eventData.payload))
      const socketError = {
        code: 'socket.error',
        message: 'There is an error with the websocket connection'
      }
      this.context.commit(Mutations.SET_ADMIN_ERRORS, socketError)
      this.context.commit(Mutations.SET_SHOW_WEBSOCKET_ERROR, true)
    }

    @Action
    [Actions.ON_PING_RESPONSE] (eventData) {
      const pingReqId = eventData.pingRequestId

      if (pingReqId !== this.context.getters.getLastPingRequestId) {
        return
      }

      this.context.commit(Mutations.SET_LAST_PING_REQUEST_ID, null)
      // todo hide the errors if any
    }

    @Action
    [Actions.PING_REQUEST] () {
      if (!this.pingEnabled) {
        return
      }
      if (this.context.getters.getPingTimer !== 0 && this.context.getters.getPingTimer !== null) {
        this.context.commit(Mutations.CLEAR_TIMEOUT_PING_TIMER)
      }

      this.context.commit(Mutations.SET_LAST_PING_REQUEST_ID, 'ping-test-' + uuid.v4())

      const emmitTime = new Date().getTime()
      const pingData: SocketPingData = {
        pingRequestId: this.context.getters.getLastPingRequestId,
        emmitTime: emmitTime,
        emmitDateTime: moment(emmitTime).format(),
        maxPingResponseTime: this.maxPingResponseTime
      }
      this.socket.emit('PING_REQUEST', pingData)

      this.context.commit(Mutations.SET_PING_TIMER, window.setTimeout(
        () => {
          this.context.commit(Mutations.SET_PING_TIMER, null)

          if (this.context.getters.getLastPingRequestId != null) {
            log.error('SOCKET MANAGER', 'The last ping request (' + this.lastPingRequestId + ') did not received a response in: ' + this.maxPingResponseTime + 's.')
            // todo show errors here
          }
          // continue pinging
          this.context.dispatch(Actions.PING_REQUEST).then()
        },
        this.maxPingResponseTime * 1000
      ))
    }
}
