/*
 * This class is used for logging purposes.
 * The class auto initialiez based on config.
 */

/* PRIVATE AREA */
const logger = {
  writers: {},
  levelsMapping: {
    EMERG: 0,
    ALERT: 1,
    CRIT: 2,
    ERROR: 3,
    WARNING: 4,
    NOTICE: 5,
    INFO: 6,
    DEBUG: 7
  },
  /**
     * Attach the writers according with config requirements.
     * @param callback The callback to be executed when writers are attached.
     * @returns {undefined}
     */
  setupWriters: function (callback) {
    const self = logger; let fileWriter
    const initFileWriter = function () {
      fileWriter = require('./log/writers/file.js')

      fileWriter.init(function () {
        if (fileWriter.isInit()) {
          self.writers.file = fileWriter
        }

        callback()
      })
    }

    // init the file writer since was not disabled by syslog
    initFileWriter()
  },
  /**
   * Log a message.
   * @param {string} level
   * @param {array} args The initial arguments of the log calls.
   * @returns {undefined}
   */
  log: function (level, args) {
    const self = logger
    let configuredLevel = 'DEBUG'
    const requestedLevel = self.levelsMapping[level]

    // mistake proof
    if (typeof self.levelsMapping[configuredLevel] === 'undefined') {
      configuredLevel = self.levelsMapping.INFO
    } else {
      configuredLevel = self.levelsMapping[configuredLevel]
    }

    if (configuredLevel < requestedLevel) {
      // no log should be writen
      return
    }

    // create the log line
    const header = self.getHeader(level, args)
    const logData = self.getMessage(args, header)

    for (const i in self.writers) {
      self.writers[i].write(logData, level)
    }
  },
  /**
     * Create the standard header of lines based on received arguments.
     * @param {string} level
     * @param {array} args
     * @returns {undefined}
     */
  getHeader: function (level, args) {
    const now = new Date()

    const self = logger
    // the header date have this format: YYYY-mm-dd hh:ii:ss.milliseconds
    const year = now.getFullYear(); let month = (now.getMonth() + 1); let day = now.getDate()
    let hours = now.getHours(); let minutes = now.getMinutes(); let seconds = now.getSeconds()
    let milliseconds = now.getMilliseconds()

    month = self.zeropad(month)
    day = self.zeropad(day)
    hours = self.zeropad(hours)
    minutes = self.zeropad(minutes)
    seconds = self.zeropad(seconds)
    milliseconds = (milliseconds + '').substr(0, 3)

    while (milliseconds.length < 3) {
      milliseconds = milliseconds + '0'
    }

    let header = year + '-' + month + '-' + day +
            ' ' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + '|'

    // the next event item after the date is the entity type
    // the entity type should pe pad-ed in order to have same length
    // in the entity may write the user's session id (userSessionId)
    // the user session id should not exceed 15 digits

    let entityType = ''

    if (typeof args[1] !== 'undefined') {
      entityType = args[1]
    }

    header += self.strpad(entityType, 15) + '|' + self.strpad(level, 7) + '| '

    return header
  },
  /**
     * Compute the logging message.
     * The logging message may be created from:
     *  - string
     *  - multiline string
     *  - objects or arrays (in future)
     * @param {array} args
     * @param {type} header
     * @returns {undefined}
     * @todo add support for array and objects on "DEBUG" level
     */
  getMessage: function (args, header) {
    const self = logger
    const dataType = typeof args[0]
    const message = args[0]
    let result = ''

    switch (dataType.toLowerCase()) {
      case 'string':
        result += self.debugString(message, header)
        break

      case 'boolean':
        result += header + ((message) ? 'true' : 'false')
        break

      case 'number':
        result += header + message
        break

        // object applies to array also
      case 'object':

        result += self.debugObject(message, header)
        // } else {
        //     result += "[Object]";
        // }
        break
    }

    return result
  },
  strpad: function (str, length, trimIfBigger) {
    const actualLength = str.length

    if (actualLength < length) {
      for (let i = 0; i < length - actualLength; i++) {
        str += ' '
      }
    }

    if (trimIfBigger) {
      str = str.substring(0, length)
    }

    return str
  },
  zeropad: function (number) {
    if (number < 10) {
      number = '0' + number
    }

    return number
  },
  debugString: function (str, header) {
    let result = ''

    if (typeof header === 'undefined') {
      header = ''
    }

    const data = str.split('\n')

    for (let i = 0; i < data.length; i++) {
      result += header + data[i] + '\n'
    }

    return result
  },
  /**
     * Debug an object.
     * @param {object|array} obj
     * @param {string} header The header value is used when iterating recursively over object properties.
     * @returns {string}
     * @todo Update this method to browse object properties on DEBUG level
     */
  debugObject: function (obj, header) {
    const self = logger

    if (typeof header === 'undefined') {
      header = ''
    }

    const util = require('util')

    const objectData = util.inspect(obj, { showHidden: true, depth: null })

    return self.debugString(objectData, header)
  },
  captureConsole: function () {
    const self = logger
    const originalCallback = console.log

    console.log = function () {
      for (const i in arguments) {
        originalCallback(arguments[i])
      }

      arguments[arguments.length] = 'CONSOLE'

      self.log('DEBUG', arguments)
    }
  }
}

module.exports = {
  /**
     * Init the logger object.
     * @param {function} callback
     * @returns {undefined}
     */
  init: function (callback) {
    logger.setupWriters(function () {
      let initializationMessage = 'Logger initialized. The messages will be written in '

      if (typeof logger.writers.syslog !== 'undefined' && typeof logger.writers.file !== 'undefined') {
        initializationMessage += 'syslog and file (' + process.env.VUE_APP_NODEJS_LOGGING_PATH + ').'
      } else if (typeof logger.writers.syslog !== 'undefined') {
        initializationMessage += 'syslog only.'
      } else if (typeof logger.writers.file !== 'undefined') {
        initializationMessage += 'file only (' + process.env.VUE_APP_NODEJS_LOGGING_PATH + ').'
      } else {
        initializationMessage = 'Error: Failed initializing loggers system. Logging will be done at console.'
      }
      console.log(initializationMessage)

      // on debug level display also the console messages
      // if (config.get("nodejs.log.level") == "DEBUG") {
      logger.captureConsole()
      // }

      callback()
    })
  },
  debugObject: function (obj) {
    return logger.debugObject(obj)
  },
  /**
     * Log amessage on EMERGENCY level.
     * @param {string} message
     * @param {string} entityType
     * - guest
     * - user's session id
     * - server
     * - core
     * @returns {undefined}
     */
  emerg: function (message, entityType) {
    logger.log('EMERG', arguments)
  },
  /**
     * Log a message on ALERT level.
     * @param {string} message
     * @param {string} entityType
     * - guest
     * - user's session id
     * - server
     * - core
     * @returns {undefined}
     */
  alert: function (message, entityType) {
    logger.log('ALERT', arguments)
  },
  /**
     * Log a message on CRIT level.
     * @param {string} message
     * @param {string} entityType
     * - guest
     * - user's session id
     * - server
     * - core
     * @returns {undefined}
     */
  crit: function (message, entityType) {
    logger.log('CRIT', arguments)
  },
  /**
     * Log a message on ERROR level.
     * @param {string} message
     * @param {string} entityType
     * - guest
     * - user's session id
     * - server
     * - core
     * @returns {undefined}
     */
  error: function (message, entityType) {
    logger.log('ERROR', arguments)
  },
  /**
     * Log a message on WARNING level.
     * @param {string} message
     * @param {string} entityType
     * - guest
     * - user's session id
     * - server
     * - core
     * @returns {undefined}
     */
  warning: function (message, entityType) {
    logger.log('WARNING', arguments)
  },
  warn: function (message, entityType) {
    logger.log('WARNING', arguments)
  },
  /**
     * Log a message on NOTICE level.
     * @param {string} message
     * @param {string} entityType
     * - guest
     * - user's session id
     * - server
     * - core
     * @returns {undefined}
     */
  notice: function (message, entityType) {
    logger.log('NOTICE', arguments)
  },
  /**
     * Log a message on INFO level.
     * @param {string} message
     * @param {string} entityType
     * - guest
     * - user's session id
     * - server
     * - core
     * @returns {undefined}
     */
  info: function (message, entityType) {
    logger.log('INFO', arguments)
  },
  /**
     * Log a message on DEBUG level.
     * @param {string} message
     * @param {string} entityType
     * - guest
     * - user's session id
     * - server
     * - core
     * @returns {undefined}
     */
  debug: function (message, entityType) {
    logger.log('DEBUG', arguments)
  }
}
