import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface Employee {
    userUuid: string,
    email: string,
    fullName: string,
    businessUnit: string,
    division: string,
    userRole: 1,
    department: string,
    costCentre: string,
    managerEmail: string,
    managerFullName: string
}

@Module
export default class EmployeeModule extends VuexModule {
    employees = [];
    employeesCount = 0;
    employeesLoading = false;
    employee = {} as Employee;

    /**
     * Get the list of employees filtered
     * @returns array
     */
    get getEmployees (): Array<string> {
      return this.employees
    }

    /**
     * Get the count for the list of employees
     * @returns number
     */
    get getEmployeesCount (): number {
      return this.employeesCount
    }

    /**
     * Get the state of the employees
     */
    get getEmployeesLoading (): boolean {
      return this.employeesLoading
    }

    /**
     * Get the employee
     * @returns Employee
     */
    get getEmployee (): Employee {
      return this.employee
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_EMPLOYEES] (payload) {
      this.context.commit(Mutations.SET_EMPLOYEES_LOADING, true)
      const response = await ApiService.get('api/v1/employees', '', payload)
      if (response.status === 200) {
        this.context.commit(Mutations.SET_EMPLOYEES_LOADING, false)
      }
      const employees = response.data.payload
      return { employees }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/employees', 'count', payload)
      const employeesCount = response.data.payload.count
      return { employeesCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_EMPLOYEE] (employeeUuid) {
      const response = await ApiService.get('api/v1/employees/' + employeeUuid)
      const employee = response.data.payload
      this.context.commit(Mutations.SET_EMPLOYEE, response.data.payload)
      return { employee }
    }

    @Action({ rawError: true })
    async [Actions.API_DELETE_EMPLOYEE] (employeeUuid) {
      return new Promise((resolve, reject) => {
        ApiService.delete('api/v1/employees/' + employeeUuid)
          .then(({ data }) => {
            this.context.commit(Mutations.REMOVE_EMPLOYEE, employeeUuid)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_CREATE_EMPLOYEE] (employeePayload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/employees', employeePayload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPDATE_EMPLOYEE] (employeePayload: any) {
      return new Promise((resolve, reject) => {
        ApiService.put('api/v1/employees/' + employeePayload.userUuid, employeePayload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_GET_EMPLOYEE] (userUuid) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/employees/' + userUuid)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Mutation
    [Mutations.SET_EMPLOYEES_LOADING] (flag: boolean) {
      this.employeesLoading = flag
    }

    @Mutation
    [Mutations.SET_EMPLOYEE] (employee) {
      this.employee = employee
    }

    @Mutation
    [Mutations.REMOVE_EMPLOYEE] (employeeUuid) {
      let removedEmployee = {} as any
      for (let i = 0; i < this.employees.length; i++) {
        removedEmployee = this.employees[i] as any
        if (removedEmployee.activitySessionUserUuid === employeeUuid) {
          this.employees.splice(i, 1)
        }
      }
    }
}
