import { createApp } from 'vue'
import App from './App.vue'

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router/clean'
import store from './store'
import ElementPlus from 'element-plus'
import i18n from '@/core/plugins/i18n'
import VueSnip from 'vue-snip'
import localforage from 'localforage'
// imports for app initialization
import ApiService from '@/core/services/ApiService'
import { initApexCharts } from '@/core/plugins/apexcharts'
import { initInlineSvg } from '@/core/plugins/inline-svg'
import { initVeeValidate } from '@/core/plugins/vee-validate'
import mitt from 'mitt' // Import mitt
import '@/core/plugins/prismjs'
import 'bootstrap'
// import { useRouter } from 'vue-router'
// import { Actions } from '@/store/enums/StoreEnums'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
import { updateAvailable } from './core/helpers/useUpdateNotifier.js'
import VueSplide from '@splidejs/vue-splide'
import '@splidejs/vue-splide/dist/css/themes/splide-default.min.css'
/**
 * Local forage main configuration.
 */
localforage.config({
  name: 'teamlearning',
  storeName: 'store'
})

const emitter = mitt() // Initialize mitt

const app = createApp(App)
app.provide('$updateNotifier', updateAvailable)
// const updateAvailable = { useUpdateNotifier }
app.config.globalProperties.$updateNotifier = updateAvailable
// main.js (or main.ts)
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope)
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing
          if (newWorker) {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // Trigger the custom 'swUpdated' event when a new service worker is installed
                  const updateEvent = new Event('swUpdated')
                  console.log('dispatch swUpdated here')
                  window.dispatchEvent(updateEvent)
                }
              }
            })
          }
        })
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error)
      })
  })
}
/**
 * Check the user token is exist or not, depends on that we redirect the user
 * to the requested page or if the page needs authentication redirect to
 * the login page to enter the credentials.
 */

app.provide('emitter', emitter)
app.use(store)
app.use(router)
// @ts-ignore
app.use(ElementPlus)
app.use(VueSnip)
// @ts-ignore
app.use(Vue3Lottie)
app.use(VueTelInput)
app.use(ContextMenu)
app.use(VueSplide)
ApiService.init(app)
initApexCharts(app)
initInlineSvg(app)
initVeeValidate()

app.use(i18n)

router.isReady().then(() => {
  app.mount('#app')
})
