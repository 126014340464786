import { Module, VuexModule, Action } from 'vuex-module-decorators'
import { Actions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

@Module
export default class PublicSessionModule extends VuexModule {
  @Action({ rawError: true })
  async [Actions.API_PUBLIC_SESSION_CREATE] (payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/v1/public-session/' + payload.templateUuid + '/generate', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
