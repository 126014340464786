import { Module, VuexModule, Mutation, MutationAction, Action } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface ActivitySession {
    activitySessionUuid: string;
    adminId: number;
    activityId: number;
    countryId: number;
    industryId: number;
    departmentId: number;
    name: string;
    status: number;
    language: string;
    maxTeamPlayers: number;
    startDate: string;
    expiryDate: string;
    createdAt: string;
    updatedAt: string;
    teamCount: number;
    userCount: number;
}

export interface ActivitySessionFilter {
    filter: {
        limit?: number,
        offset?: number
        name?: string,
        status?: number,
        employeeUuid?: string
    }
}

interface ActivitySessionList {
    activitySessions: Array<string>
    activitySessionsCount: number,
    activitySessionRequestPending: boolean
}

@Module
export default class ActivitySessionModule extends VuexModule implements ActivitySessionList {
    activitySessions = [];
    activitySessionsCount = 0;
    activitySessionsInProgressCount = 0;
    activitySessionsScheduledCount = 0;
    activitySessionsFinishedCount = 0;
    activitySessionRequestPending = false;

    /**
     * Get the list of activity sessions filtered
     * @returns array
     */
    get getActivitySessions (): Array<string> {
      return this.activitySessions
    }

    /**
     * Get the count for the list of activity sessions
     * @returns number
     */
    get getActivitySessionsCount (): number {
      return this.activitySessionsCount
    }

    /**
     * Get the count of in progress activity sessions
     * @returns number
     */
    get getActivitySessionsInProgressCount (): number {
      return this.activitySessionsInProgressCount
    }

    /**
     * Get the count of scheduled activity sessions
     * @returns number
     */
    get getActivitySessionsScheduledCount (): number {
      return this.activitySessionsScheduledCount
    }

    /**
     * Get the count of finished activity sessions
     * @returns number
     */
    get getActivitySessionsFinishedCount (): number {
      return this.activitySessionsFinishedCount
    }

    /**
     * Get the api request status for activity sessions
     * @returns boolean
     */
    get getActivitySessionRequestPending (): boolean {
      return this.activitySessionRequestPending
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS] (payload) {
      this.context.commit(Mutations.API_SET_ACTIVITY_SESSION_REQUEST_PENDING, true)
      const response = await ApiService.get('api/v1/activity-sessions', '', payload)
      if (response.status === 200) {
        this.context.commit(Mutations.API_SET_ACTIVITY_SESSION_REQUEST_PENDING, false)
      }
      const activitySessions = response.data.payload
      return { activitySessions }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-sessions', 'count', payload)
      const activitySessionsCount = response.data.payload.count
      return { activitySessionsCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_IN_PROGRESS_COUNT] () {
      const payload = { filter: { status: 3 } }
      const response = await ApiService.get('api/v1/activity-sessions', 'count', payload)
      const activitySessionsInProgressCount = response.data.payload.count
      return { activitySessionsInProgressCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_SCHEDULED_COUNT] () {
      const payload = { filter: { status: 2 } }
      const response = await ApiService.get('api/v1/activity-sessions', 'count', payload)
      const activitySessionsScheduledCount = response.data.payload.count
      return { activitySessionsScheduledCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_FINISHED_COUNT] () {
      const payload = { filter: { status: 4 } }
      const response = await ApiService.get('api/v1/activity-sessions', 'count', payload)
      const activitySessionsFinishedCount = response.data.payload.count
      return { activitySessionsFinishedCount }
    }

    @Action({ rawError: true })
    async [Actions.API_DELETE_ACTIVITY_SESSION] (activitySessionUuid) {
      return new Promise((resolve, reject) => {
        ApiService.delete('api/v1/activity-sessions/' + activitySessionUuid)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_GET_ACTIVITY_SESSION] (activitySessionUuid) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/activity-sessions/' + activitySessionUuid)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Mutation
    [Mutations.API_SET_ACTIVITY_SESSIONS] (activitySessions) {
      this.activitySessions = activitySessions
    }

    @Mutation
    [Mutations.API_SET_ACTIVITY_SESSIONS_COUNT] (count) {
      this.activitySessionsCount = count
    }

    @Mutation
    [Mutations.API_SET_ACTIVITY_SESSIONS_IN_PROGRESS_COUNT] (count) {
      this.activitySessionsInProgressCount = count
    }

    @Mutation
    [Mutations.API_SET_ACTIVITY_SESSIONS_SCHEDULED_COUNT] (count) {
      this.activitySessionsScheduledCount = count
    }

    @Mutation
    [Mutations.API_SET_ACTIVITY_SESSIONS_FINISHED_COUNT] (count) {
      this.activitySessionsFinishedCount = count
    }

    @Mutation
    [Mutations.API_SET_ACTIVITY_SESSION_REQUEST_PENDING] (value) {
      this.activitySessionRequestPending = value
    }
}
