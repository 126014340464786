import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface EmployeeStatistics {
    engagementScore: number,
    engagement: string,
    confidenceScore: number,
    confidence: string,
    skillScore: number,
    skill: string,
    attended: boolean | null | number
}

export interface EmployeeStatisticForSession {
    activitySession: {
        activitySessionUuid: string;
        activityUuid: string;
        learningPathUuid: string;
        learningPathTitle: string;
        name: string;
        status: number;
        startDate: string;
        expiryDate: string;
    },
    employeeStatistics: EmployeeStatistics
}

export interface Metrics {
    numAccounts?: number
    numSessions?: number
    inProgressSessions?: number
    inScheduledSessions?: number
    finishedSessions?: number
    numUniqueUsers?: number
    finishedLearningPaths?: number
    totalActiveUsersLoggedIn?: number
    employeeStatisticsForSessions?: Array<EmployeeStatisticForSession>
    employeeStatisticsForSessionsAverage?: EmployeeStatistics
}

export interface MetricsFilter {
    startDate?: string
    endDate?: string
    companies?: Array<any>
    accounts?: Array<any>
    employees?: Array<any>
    activitySessionUuid?: string
    learningPathUuid?: string
    status?: number
    name?: string
    order?: string
    offset?: number
    limit?: number
    format?: string
}

export interface MetricsPayload {
    metrics: Array<string>
    filter: MetricsFilter
}

@Module
export default class StatisticModule extends VuexModule {
    metrics = {} as Metrics

    get getMetrics () {
      return this.metrics
    }

    get getEmployeeStatisticsForSessions () {
      return this.metrics.employeeStatisticsForSessions ?? []
    }

    get getEmployeeStatisticsForSessionsAverage () {
      return this.metrics.employeeStatisticsForSessionsAverage ?? []
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_METRICS_BY_FILTER] (filter) {
      const response = await ApiService.get('api/v1/statistics', '', filter)
      const metrics = response.data.payload.metrics

      return { metrics }
    }

    @Action
    async [Actions.API_GET_METRICS_BY_FILTER] (filter) {
      if ('format' in filter.filter) { // means it is a file response
        const response = await ApiService.get('api/v1/statistics', '', filter, { responseType: 'arraybuffer' })
        // Download file
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const href = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        const filename = response.headers['content-disposition'].split('"')[1]
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        // Clean download
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      } else {
        const response = await ApiService.get('api/v1/statistics', '', filter)
        const metrics = response.data.payload.metrics
        if ('employeeStatisticsForSessions' in metrics) {
          this.context.commit(Mutations.SET_EMPLOYEE_STATISTICS_FOR_SESSIONS, metrics.employeeStatisticsForSessions)
        }
        if ('employeeStatisticsForSessionsAverage' in metrics) {
          this.context.commit(Mutations.SET_EMPLOYEE_STATISTICS_FOR_SESSIONS_AVERAGE, metrics.employeeStatisticsForSessionsAverage)
        }
      }
    }

    @Mutation
    [Mutations.SET_EMPLOYEE_STATISTICS_FOR_SESSIONS] (employeeStatisticsForSessions) {
      this.metrics.employeeStatisticsForSessions = employeeStatisticsForSessions
    }

    @Mutation
    [Mutations.SET_EMPLOYEE_STATISTICS_FOR_SESSIONS_AVERAGE] (statisticsAverages) {
      this.metrics.employeeStatisticsForSessionsAverage = statisticsAverages
    }
}
