
import { defineComponent, onMounted, nextTick, computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { initializeComponents } from '@/core/plugins/keenthemes'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { ElNotification } from 'element-plus/es'
import { useRoute } from 'vue-router'
import UpdateNotification from '@/views/UpdateNotification.vue'

export default defineComponent({
  name: 'app',
  components: { UpdateNotification },
  setup () {
    const store = useStore()
    // const router = useRouter()
    const route = useRoute()
    const path = computed(() => route.name) as any
    const showingMultipleWindowsError = computed(() => store.getters.showingMultipleWindowsError)
    const showingWebsocketError = computed(() => store.getters.showingWebsocketError)
    // no need for token here
    if (!['activation', 'sign-in', 'sign-up', 'account-activation', 'validation'].includes(path.value)) {
      store.dispatch(Actions.SET_TOKEN).then(() => {
        store.dispatch(Actions.FETCH_USER).catch((error) => {
          console.error('Failed retrieving user', error)
          store.dispatch(Actions.CLEAR_AUTHENTICATION).then(() => {
            // router.replace({ name: 'sign-in' })
            window.location.href = '/sign-in'
          }).catch((error) => {
            console.log(error)
            // router.replace({ name: 'sign-in' })
            window.location.href = '/sign-in'
          })
        })
      }).catch((error) => {
        console.error('setting token', error)
        store.dispatch(Actions.CLEAR_AUTHENTICATION)
      })
    }
    // /**
    //  * this is to override the layout config using saved data from localStorage
    //  * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
    //  */
    store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG)
    const details = reactive({ account: computed(() => store.getters.currentAccount), socket: computed(() => store.getters.getSocket) })
    const errors = reactive({ error: computed(() => store.getters.getAdminErrors) })
    watch(() => errors.error, (newError, oldErrors) => {
      if (newError.code) {
        ElNotification.error({
          message: newError.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left',
          duration: 10000
        })
      }
    })
    // let socketConnected = false

    watch(() => details.account, (account, oldAccount) => {
      if (account && account.accountUuid && (typeof details.socket.disconnected === 'undefined' || details.socket.disconnected)) {
        store.dispatch(Actions.CREATE_SOCKET_CONNECTION, account.accountUuid).then(() => {
          // socketConnected = true
        })
      }
    })
    onMounted(() => {
      nextTick(() => {
        initializeComponents()
      })
    })

    return {
      showingMultipleWindowsError,
      showingWebsocketError
    }
  }
})
