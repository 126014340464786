import { createStore } from 'vuex'
import { config } from 'vuex-module-decorators'

import AuthModule from '@/store/modules/AuthModule'
import AccountModule from '@/store/modules/AccountModule'
import ActivitySessionModule from '@/store/modules/ActivitySessionModule'
import BodyModule from '@/store/modules/BodyModule'
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule'
import ActionButtonModule from '@/store/modules/ActionButtonModule'
import ConfigModule from '@/store/modules/ConfigModule'
import TemplateModule from '@/store/modules/TemplateModule'
import WebsocketModule from '@/store/modules/WebsocketModule'
import EmployeeModule from '@/store/modules/EmployeeModule'
import LearningPathModule from '@/store/modules/LearningPathModule'
import StatisticModule from '@/store/modules/StatisticModule'
import OpenAiModule from '@/store/modules/OpenAiModule'
import UnsplashModule from '@/store/modules/UnsplashModule'
import IconFinderModule from '@/store/modules/IconFinderModule'
import PublicSessionModule from '@/store/modules/PublicSessionModule'
import AttachmentModule from '@/store/modules/AttachmentModule'
config.rawError = true

const store = createStore({
  modules: {
    AuthModule,
    AccountModule,
    ActivitySessionModule,
    BodyModule,
    BreadcrumbsModule,
    ActionButtonModule,
    ConfigModule,
    TemplateModule,
    WebsocketModule,
    EmployeeModule,
    LearningPathModule,
    StatisticModule,
    OpenAiModule,
    UnsplashModule,
    IconFinderModule,
    PublicSessionModule,
    AttachmentModule
  }
})

export default store
