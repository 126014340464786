const ACCOUNT_UUID = 'csrf_account_uuid' as string

/**
 * @description get csrf account uuid from localStorage
 */
export const getCsrfAccountUuid = (): string | null => {
  return window.localStorage.getItem(ACCOUNT_UUID)
}

/**
 * @description save csfr account uuid into localStorage
 * @param uuid: string
 */
export const saveCsrfAccountUuid = (uuid: string): void => {
  window.localStorage.setItem(ACCOUNT_UUID, uuid)
}

/**
 * @description remove csrf account uuid form localStorage
 */
export const destroyCsrfAccountUuid = (): void => {
  window.localStorage.removeItem(ACCOUNT_UUID)
}

export default { getCsrfAccountUuid, saveCsrfAccountUuid, destroyCsrfAccountUuid }
