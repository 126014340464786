import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Mutations, Actions, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface Company {
    name: string;
    uuid: string;
}

export interface Account {
    accountUuid: string;
    type: number;
    email: string;
    company: Company;
    firstName: string;
    lastName: string;
    fullName: string;
    phone?: string;
    createdAt: string;
    updatedAt: string;
}

@Module
export default class AccountModule extends VuexModule {
    account = {} as Account;
    company = {};

    get getCompany () {
      return this.company
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_COMPANY_BY_UUID] (uuid) {
      const response = await ApiService.get('api/v1/companies', uuid)
      const company = response.data.payload
      return { company }
    }

    @Action({ rawError: true })
    [Actions.GET_ACCOUNT_BY_UUID] (payload) {
      return ApiService.get('api/v1/account', 'getByUuid', { accountUuid: payload.uuid })
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          if (response.status === 404 || response.status === 422 || response.status === 400 || response.status === 500) {
            this.context.commit(Mutations.SET_ERROR, [{ 0: 'Could not sign in.' }])
          } else {
            this.context.commit(Mutations.SET_ERROR, [{ 0: response.data.error.message }])
          }
        })
    }

    @Mutation
    [Mutations.SET_ACCOUNT] (account) {
      this.account = account
    }

    @Action
    [Actions.API_GET_AVATAR_URL] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/accounts/' + payload.accountUuid, 'profile-avatar', { profileAvatarPath: payload.avatarPath })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action
    [Actions.API_ACCOUNT_REQUEST_ACTIVATE] (payload) {
      return ApiService.post('api/v1/account/validation/request', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {})
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [{ 0: response.data.error.message }])
        })
    }

    @Action
    [Actions.API_ACCOUNT_CHECK_ACTIVATE] (payload) {
      return ApiService.post('api/v1/account/validation/check', payload)
        .then(({ data }) => {
          return true
        })
        .catch(({ response }) => {
          return false
        })
    }

    @Action
    [Actions.API_ACCOUNT_SUBMIT_ACTIVATE] (credentials) {
      return ApiService.post('api/v1/account/validation/submit', credentials)
        .then(({ data }) => {

        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [{ 0: response.data.error.message }])
        })
    }

    @Action({ rawError: true })
    [Actions.API_ACCOUNT_EMAIL_SUBMIT_ACTIVATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/account/validation/email/submit', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    [Actions.API_UPDATE_ACCOUNT] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/accounts/' + payload.accountUuid, payload.data, payload.config)
          .then(({ data }) => {
            console.log('data', data)
            this.context.commit(Mutations.SET_USER, data.payload)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
}
