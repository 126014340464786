import { Action, Module, VuexModule } from 'vuex-module-decorators'
import { Actions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

@Module
export default class UnsplashModule extends VuexModule {
    photos = [] as any

    @Action({ rawError: true })
    async [Actions.API_UNSPLASH_SEARCH_PHOTOS] (params: { page:0, query: '' }) {
      return new Promise((resolve, reject) => {
        const payload = params as any
        ApiService.post('api/v1/unsplash/search', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UNSPLASH_FETCH_IMAGE] (downloadUrl) {
      return new Promise((resolve, reject) => {
        fetch(downloadUrl, { headers: { Authorization: 'Client-ID uWxVUismei3ewEg6E6BmCSw2FKvnIUtTRx2RySmDXGw' } })
          .then((response) => {
            resolve(response.json())
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
}
